<template>
  <div style="text-align:center">
      <p v-html="infoList"></p>
  </div>
</template>

<script>
export default {
    data(){
        return{
            infoList:''
        }
    },
    mounted(){
        this.infoList = this.$route.query.info
    }
}
</script>

<style>

</style>